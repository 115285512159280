<template>
  <v-file-input
    v-if="fileType"
    v-model="formData"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    :class="{
      'trs-input': true,
      'drag-highlight': dragHighlight !== 0
    }"
    :multiple="multiple"
    :accept="allowedFileTypes"
    @change="uploadFile"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot
        :name="slot"
      />
    </template>
    <!-- https://github.com/vuetifyjs/vuetify/issues/11533 -->
    <template slot="label">
      <slot name="label" />
    </template>
    <template
      v-if="'chips' in $attrs"
      v-slot:selection="{ text, index }"
    >
      <v-chip
        medium
        label
        close
        @click:close="deleteChip(index, text)"
      >
        {{ text }}
      </v-chip>
    </template>
  </v-file-input>
</template>

<script>
  import fileDragMixin from '@/components/core/mixins/file-drag'

  export default {
    name: 'MultiFileUpload',

    mixins: [fileDragMixin],

    props: {
      value: {
        type: Array,
      },
      fileType: {
        type: String,
        required: true,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        allowedFileTypes: [],
        defaultAttrs: {
          filled: true,
          color: 'primary',
          validateOnBlur: true,
          'prepend-icon': '',
          'prepend-inner-icon': 'mdi-paperclip',
        },
      }
    },

    computed: {
      formData: {
        get () {
          return this.value || {}
        },
        set (val) {
          this.$emit('input', val)
        },
      },
      fileTypeMsg () {
        switch (this.fileType) {
          case this.CONSTS.FILE_TYPES.DOCUMENT: return 'PDF'
          case this.CONSTS.FILE_TYPES.IMAGE: return 'JPEG / PNG'
          case this.CONSTS.FILE_TYPES.DATA: return 'excel sheet / CSV'
          case this.CONSTS.FILE_TYPES.ZIP: return 'ZIP'
          case this.CONSTS.FILE_TYPES.ALL_DOCS: return 'PDF / excel '
          default: return 'PDF'
        }
      },
    },
    watch: {
      fileType: {
        handler (val) {
          if (val) {
            const types = this.CONSTS.FILE_MIME_TYPES.filter((obj) => {
              if (obj.type === val) {
                return obj
              }
            })
            this.allowedFileTypes = types[0] && types[0].value
          }
        },
        immediate: true,
      },
    },

    methods: {
      deleteChip (index, text) {
        this.formData.splice(index, 1)
      },
      uploadFile (file) {
        if (this.multiple) {
          this.multipleFileUpload(file)
        } else {
          this.singleFileUpload(file)
        }
      },
      singleFileUpload (file) {
        if (file && this.fileType && this.allowedFileTypes.includes(file.type)) {
          this.$emit('change', file)
        } else if (file) {
          this.$store.dispatch('app/handleError', {
            message: `Please upload ${this.fileTypeMsg} files only`,
          })
        } else {
          this.$emit('change', file)
        }
      },
      multipleFileUpload (fileSet) {
        fileSet.forEach(file => {
          this.singleFileUpload(file)
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.drag-highlight {
  box-shadow: 0 0 2px 2px var(--v-primary-base);
  ::v-deep input {
    pointer-events: all;
    min-width: 100%;
    min-height: 100%;
  }
}
</style>
