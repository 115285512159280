<template>
  <div>
    <div class="trsText--text font-weight-medium text-h4">
      Notes
    </div>
    <common-input-textarea
      v-model="notes"
      class="mt-3"
      hide-details
      single-line
      placeholder="Add notes"
    />
    <div class="trsText--text font-weight-medium text-h4 mt-6">
      Documents
    </div>
    <multi-file-upload
      v-model="documentList"
      chips
      class="mt-3 doc-upload"
      color="primary"
      :label="'Select a file to Upload'"
      :hint="'Multiple documents can be selected and uploaded together at once.'"
      persistent-hint
      :multiple="true"
      :file-type="CONSTS.FILE_TYPES.ALL_DOCS"
    />
    <div
      v-if="uploadedDocuments.length>0"
      class="my-2"
    >
      <div class="trsText--text font-weight-medium text-h4 mt-4 mb-2">Uploaded Documents</div>
      <ul>
        <li
          v-for="item of uploadedDocuments"
          :key="item.id"
        >
          <div>
            <v-chip
              medium
              label
              close
              @click:close="deleteConfirmModal(item)"
            >
              {{ item.name }}
              <a
                :href="item.url"
                @click.prevent="downloadItem(item)"
              > <v-icon class="mx-1">mdi-download</v-icon>
              </a>
            </v-chip>
          </div>
        </li>
      </ul>
    </div>
    <generic-delete-modal
      v-model="docDeleteModal"
      @yes="deleteDoc"
      @close="closeModal"
    />
  </div>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import MultiFileUpload from '@/components/common/components/MultiFileUpload'
  import GenericDeleteModal from '../GenericDeleteModal'
  export default {
    name: 'NoteAndDocumentsFrom',

    components: {
      MultiFileUpload,
      GenericDeleteModal,
    },

    props: {
      id: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
      mode: {
        type: String,
        default: 'create',
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      documentList: [],
      notes: '',
      uploadedDocuments: [],
      docDeleteModal: false,
      docId: '',
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      isDialogActive: {
        get () {
          return this.internalDialog || false
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      isSubmit: {
        handler (val) {
          if (val) {
            this.setNoteAndDocs(this.id)
          }
        },
      },
      isDialogActive: {
        handler (val) {
          if (val && this.mode === 'edit') {
            this.getNotes()
            this.getDocuments()
          }
        },
        immediate: true,
      },
    },

    methods: {
      async setNoteAndDocs (id) {
        try {
          await captableService.addNotes(this.captableId, this.type, id, this.notes)
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to add notes. Please try again later.',
          })
        }
        if (this.documentList.length > 0) {
          try {
            const data = await Promise.all(this.documentList.map(doc => this.documentSubmit(id, doc)))
            if (data) {
              this.promiseResolved()
            }
          } catch (err) {
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Sorry! Unable to add notes. Please try again later.',
            })
          }
        } else {
          this.promiseResolved()
        }
      },

      async documentSubmit (id, document) {
        try {
          const data = { name: document.name }
          const resp = await captableService.createDocument(this.captableId, this.type, id, data)
          await captableService.uploadDocument(resp.data.id, document)
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to documentSubmit. Please try again later.',
          })
        }
      },
      async getNotes () {
        try {
          const resp = await captableService.getNote(this.captableId, this.type, this.id)
          this.notes = resp.data.value.notes
        } catch (err) {}
      },
      async getDocuments () {
        try {
          const resp = await captableService.getDocuments(this.captableId, this.type, this.id)
          this.uploadedDocuments = resp.data.documents
        } catch (err) {}
      },
      deleteConfirmModal (document) {
        this.docDeleteModal = true
        this.docId = document.id
      },
      async deleteDoc () {
        try {
          await captableService.deleteDocuments(this.docId)
          this.docDeleteModal = false
          this.$store.dispatch('app/message', { text: 'Document deleted successfully.' })
          this.getDocuments()
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to delete document. Please try again later.',
          })
        }
      },
      closeModal () {
        this.docDeleteModal = false
      },
      base64ToArrayBuffer (base64) {
        const binaryString = window.atob(base64)
        const binaryLen = binaryString.length
        const bytes = new Uint8Array(binaryLen)
        for (let i = 0; i < binaryLen; i++) {
          const ascii = binaryString.charCodeAt(i)
          bytes[i] = ascii
        }
        return bytes
      },
      async downloadItem (doc) {
        const label = doc.name
        try {
          const resp = await captableService.downloadDoc(doc.id)
          const base64ToBytes = this.base64ToArrayBuffer(resp.data.file)
          const blob = new Blob([base64ToBytes])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to download document. Please try again later.',
          })
        }
      },
      promiseResolved () {
        this.isDialogActive = false
        this.notes = ''
        this.documentList = []
        this.$emit('navToList', true)
      },
    },
  }
</script>

<style scoped>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left !important;
}

li div {
  display: block;
  text-align: center;
  padding: 4px;
  text-decoration: none;
}
::v-deep .doc-upload .v-text-field__details {
  padding: 4px 0px!important;
}
</style>
